html {
  font-size: calc(6px + 1.5vh)
}

#root {
  height: 100vh;
  width: 100vw;
}

body { 
  background-color: #000;
  margin: 0;
  padding: 0;
  color: white;
  overflow: hidden;
}

#container {
  position: relative;
}
#container canvas, #root {
  position: absolute;
}

canvas {
  border: 1px solid black;
}